<template>
  <div>
    <div class="a-booking a-help">
      <div class="app-container app-container-fluid a-container">
        <div class="a-booking-container">
          <div class="a-booking-content">
            <div class="a-booking-box">
              <p class="a-heading-1">{{ $t('hc.request_for_help') }}</p>
              <ValidationObserver ref="form" v-slot="{ invalid }">
                <form class="feedback-form" @submit.prevent="onSubmit">
                  <div class="mb-4">
                    <ValidationProvider
                      name="E-mail"
                      rules="required|email"
                      v-slot="{ errors, classes }"
                    >
                      <v-text-field
                        label="E-mail"
                        :placeholder="$t('hc.placeholder.your_email')"
                        name="email"
                        type="email"
                        v-model="email"
                        required
                        :class="classes"
                        :errorMessage="errors[0]"
                      />
                    </ValidationProvider>
                    <ValidationProvider
                      :name="$t('hc.label.message')"
                      rules="required"
                      v-slot="{ errors, classes }"
                    >
                      <v-text-field
                        :label="$t('hc.label.message')"
                        :placeholder="
                          $t('hc.placeholder.describe_your_problem')
                        "
                        name="textarea"
                        type="textarea"
                        v-model="text"
                        :rows="5"
                        required
                        :class="classes"
                        :errorMessage="errors[0]"
                      />
                    </ValidationProvider>
                  </div>

                  <div class="row a-booking-form-action">
                    <div class="col"></div>
                    <div class="col-auto">
                      <v-button
                        xxsmall
                        color="green white--text a-help-btn"
                        :loading="loading"
                        @click="onSubmit"
                        :disabled="invalid"
                        >{{ $t('account.buttons.send_a_request') }}</v-button
                      >
                    </div>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </div>
          <div class="a-booking-banner"></div>
        </div>
      </div>
    </div>

    <dialog-success
      :title="$t('hc.messages.help.title')"
      :text="$t('hc.messages.help.text')"
      v-model="success"
      @close-dialog="closeDialog"
    />
  </div>
</template>

<script>
import DialogSuccess from '@/views/account/home/components/DialogSuccess';
import { help } from '@/services/request';

export default {
  data: () => ({
    email: '',
    text: '',
    loading: false,
    success: false,
    errors: {},
  }),
  components: {
    DialogSuccess,
  },
  methods: {
    onSubmit() {
      if (this.$store.state.notification.isActive)
        this.$store.commit('notification/clearMessage');
      this.success = false;
      this.loading = true;

      help({ email: this.email, text: this.text })
        .then(() => {
          this.success = true;
        })
        .catch(err => {
          let data = err.response.data;
          this.$store.commit('notification/errorMessage', {
            errors: data.errors,
          });
        })
        .finally(() => (this.loading = false));
    },
    closeDialog() {
      this.email = '';
      this.text = '';
      this.success = false;
      this.loading = false;
      this.$nextTick(() => this.$refs.form.reset());
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/global/variables';
@import '@/styles/global/mixins';

.a-help {
  padding-top: 0;
  @include minw($grid-breakpoints-sm) {
    padding: 95px * 0.6 0;
  }
  @include minw($grid-breakpoints-md) {
    padding: 95px * 0.8 0;
  }
  @include minw($grid-breakpoints-lg) {
    padding: 95px 0;
  }
  .a-heading-1 {
    margin-top: 10px;
    margin-bottom: 25px;
    @include maxw($grid-breakpoints-xs - 1) {
      text-align: center;
    }
  }
  .a-booking {
    &-container {
      padding-top: 35px;
      padding-bottom: 35px;
      @include minw($grid-breakpoints-sm) {
        min-height: calc(100vh - 105px * 0.8 - 92px * 2);
        display: grid;
      }
      @include minw($grid-breakpoints-md) {
        min-height: calc(100vh - 105px - 92px * 2);
      }
    }
    &-content {
      width: 100%;
      max-width: 950px * 0.8;
      @include minw($grid-breakpoints-md) {
        max-width: 950px * 0.9;
      }
      @include minw($grid-breakpoints-lg) {
        max-width: 950px;
      }
    }
    &-banner {
      @include minw($grid-breakpoints-sm) {
        bottom: 0;
      }
    }
  }
  &-btn {
    min-width: 182px !important;
  }
}
</style>
