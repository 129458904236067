var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"a-booking a-help"},[_c('div',{staticClass:"app-container app-container-fluid a-container"},[_c('div',{staticClass:"a-booking-container"},[_c('div',{staticClass:"a-booking-content"},[_c('div',{staticClass:"a-booking-box"},[_c('p',{staticClass:"a-heading-1"},[_vm._v(_vm._s(_vm.$t('hc.request_for_help')))]),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"feedback-form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"mb-4"},[_c('ValidationProvider',{attrs:{"name":"E-mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('v-text-field',{class:classes,attrs:{"label":"E-mail","placeholder":_vm.$t('hc.placeholder.your_email'),"name":"email","type":"email","required":"","errorMessage":errors[0]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t('hc.label.message'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('v-text-field',{class:classes,attrs:{"label":_vm.$t('hc.label.message'),"placeholder":_vm.$t('hc.placeholder.describe_your_problem'),"name":"textarea","type":"textarea","rows":5,"required":"","errorMessage":errors[0]},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})]}}],null,true)})],1),_c('div',{staticClass:"row a-booking-form-action"},[_c('div',{staticClass:"col"}),_c('div',{staticClass:"col-auto"},[_c('v-button',{attrs:{"xxsmall":"","color":"green white--text a-help-btn","loading":_vm.loading,"disabled":invalid},on:{"click":_vm.onSubmit}},[_vm._v(_vm._s(_vm.$t('account.buttons.send_a_request')))])],1)])])]}}])})],1)]),_c('div',{staticClass:"a-booking-banner"})])])]),_c('dialog-success',{attrs:{"title":_vm.$t('hc.messages.help.title'),"text":_vm.$t('hc.messages.help.text')},on:{"close-dialog":_vm.closeDialog},model:{value:(_vm.success),callback:function ($$v) {_vm.success=$$v},expression:"success"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }